<template>
    <div class="qingwu">
        <div class="admin_main_block">
            <div class="admin_main_block_top">
                <div class="admin_main_block_left">
                    <div>门店编辑</div>
                </div>

                <div class="admin_main_block_right">
                    <div><el-button icon="el-icon-back" @click="$router.go(-1)">返回</el-button></div>
                </div>
            </div>

            <div class="admin_form_main">
                <el-form  label-width="100px" ref="info" :model="info">
                    <el-form-item label="门店名称" prop="F_VR_MINGC" :rules="[{required:true,message:'门店名称不能为空',trigger: 'blur' }]"><el-input placeholder="请输入门店名称" v-model="info.F_VR_MINGC" style="width:360px;"></el-input></el-form-item>
                    <el-form-item label="门店联系电话" prop="F_VR_LIANXDH" :rules="[{required:true,message:'门店联系电话不能为空',trigger: 'blur' }]"><el-input placeholder="请输入门店联系电话" v-model="info.F_VR_LIANXDH" style="width:360px;"></el-input></el-form-item>
                    <el-form-item label="门店图片" prop="thumb"><el-upload class="avatar-uploader" :action="$api.goodsClassUpload" :headers="upload_headers" :show-file-list="false" :on-success="handleAvatarSuccess" >
                        <img v-if="info.F_VR_ZHAOP" :src="info.F_VR_ZHAOP" class="avatar-upload">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload></el-form-item>
                    <el-form-item label="地址" prop="tags"><el-input  v-model="info.F_VR_DIZ" style="width:560px;"></el-input></el-form-item>
                    <el-form-item label="老师后台账号" prop="F_IN_YINGQQX_ID">
                        <el-select v-model="info.F_IN_HOUTID" placeholder="请选择老师后台账号" @change="selectChange()">
                            <el-option label="请选择老师后台账号" :value="0"></el-option>
                            <el-option v-for="(v,k) in list" :label="v.username" :key="k" :value="v.id"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="submitForm('info')">提交</el-button>
                        <el-button @click="resetForm('info')">重置</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    components: {},
    props: {},
    data() {
      return {
          edit_id:0,
          info:{
              F_VR_MINGC:'',
              F_VR_ZHAOP:'',
              F_VR_DIZ:'',
          },
          list:[],
          upload_headers:{},
      };
    },
    watch: {},
    computed: {},
    methods: {
        resetForm:function(e){
            this.$refs[e].resetFields();
        },
        submitForm:function(e){
            let _this = this;

            // 验证表单
            this.$refs[e].validate(function(res){
                if(res){
                    //  判断是Add 或者 Edit
                    let url = _this.$api.addMend;
                    if(_this.edit_id>0){
                        url = _this.$api.editMend+_this.edit_id;
                    }
                    // Http 请求
                    _this.$post(url,_this.info).then(function(res){
                        if(res.code == 200){
                            _this.$message.success("编辑成功");
                            _this.$router.go(-1);
                        }else{
                            _this.$message.error("编辑失败");
                        }
                    });
                }
            });
        },
        get_goods_class_info:function(){
            let _this = this;
            this.$get(this.$api.editMend+this.edit_id).then(function(res){
                _this.info = res.data.list;
                _this.list = res.data.info;
            })

        },
        get_goods_class_list:function(){
            let _this = this;
            this.$get(this.$api.addMend).then(function(res){
                _this.list = res.data;
            })
        },
        selectChange:function(){
            this.$forceUpdate();
        },
        handleAvatarSuccess(res) {
            this.info.F_VR_ZHAOP = res.data;
            this.$forceUpdate();
        },
    },
    created() {
        this.upload_headers.Authorization = 'Bearer '+localStorage.getItem('token'); // 要保证取到

        // 判断是否是编辑
        this.info.pid = 0;
        if(!this.$isEmpty(this.$route.params.id)){
            this.edit_id = this.$route.params.id;
        }else{
            return this.get_goods_class_list();
        }

        if(this.edit_id>0){
            this.get_goods_class_info();
        }else{
            this.get_goods_class_list();
        }



        
    },
    mounted() {}
};
</script>
<style lang="scss" scoped>

</style>